<template>
    <div class="checkout">
        <Base>
            <section class="py-5">
                <div class="container px-4 px-lg-5 mt-5">
                    <div v-if="totalPrice">
                        <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">

                            <table class="table table-striped">
                                <thead>
                                    <th></th>
                                    <th>Produto</th>
                                    <th>Quantidade</th>
                                    <th>Preço</th>
                                    <th>Subtotal</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in cartItems" :key="index">
                                        <td> <img :src="item.image" alt="" width="100"> </td>
                                        <td> {{item.name}} </td>
                                        <td> {{item.quantity}} </td>
                                        <td> R$ {{item.price}} </td>
                                        <td> R$ {{( item.quantity * (Number(item.price.replace(",","."))).toFixed(2) ).toFixed(2) }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-12" style="text-align: right">
                                <h4> Total: R$ {{totalPrice | formatPrice}}  &nbsp;&nbsp; <button class="btn btn-success btn-lg" @click="getCustomerInfo()"> COMPRAR </button> </h4>
                            </div>

                            <div class="col-md-12" v-if="lastStepActive">
                                <hr />
                                <h3> Realizar Pagamento </h3>
                                
                                <div v-html="msgError"></div>
                                <div class="form-group">
                                    <label>Nome</label>
                                    <input type="text" class="form-control" v-model="customerName" placeholder="Informe seu nome" />
                                </div>

                                <div class="form-group">
                                    <label>E-mail</label>
                                    <input type="text" class="form-control" v-model="customerEmail" placeholder="Informe seu e-mail" />
                                </div>

                                <!-- ENDEREÇO PARA ENTREGA -->
                                <div class="form-group">
                                    <label>CEP</label>
                                    <input type="text" @blur="getFullAddress()" class="form-control" v-model="cep" />
                                </div>

                                <div class="form-group">
                                    <label>Rua</label>
                                    <input type="text" class="form-control" v-model="street" />
                                </div>

                                <div class="form-group">
                                    <label>Bairro</label>
                                    <input type="text" class="form-control" v-model="village" />
                                </div>

                                <div class="form-group">
                                    <label>Cidade</label>
                                    <input type="text" class="form-control" v-model="city" />
                                </div>

                                <div class="form-group">
                                    <label>Estado</label>
                                    <input type="text" class="form-control" v-model="uf" />
                                </div>

                                <div class="form-group">
                                    <br><button  class="btn btn-success btn-lg" @click="generatePaymentCode()"> FINALIZAR COMPRA </button>
                                </div>

                                <div v-if="qrCode">
                                    <hr>
                                    <img :src="qrCode" alt="" v-if="qrCode" />
                                    <div class="form-control">
                                        <label> Pix Copia Cola: </label>
                                        <input type="text" class="form-control" v-model="pixCopyPaste" v-if="pixCopyPaste">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-md-12">
                            <p>Carrinho vazio... <br> vamos às <router-link to="/"> compras! </router-link> </p>
                        </div>
                    </div>
                </div>
            </section>
        </Base>
    </div>
</template>

<script>
import Base from '@/layouts/Base.vue';
import uniqid from 'uniqid';
import { QrCodePix } from 'qrcode-pix';
import axios from "axios";

export default {
    components: {
        Base,
    },
    data() {
        return {
            cartItems: this.$route.params,
            totalPrice: null,
            pixCopyPaste: '',
            qrCode: '',
            customerName: '',
            customerEmail: '',
            msgError: '',
            lastStepActive: false,
            cep: '',
            street: '',
            village: '',
            city: '',
            uf: '',
        }
    },
    mounted() {
        let prices = this.$route.params;
        prices = Object.values(prices);
        this.totalPrice = prices.length ? prices.map(i => i.quantity * Number(i.price.replace(",","."))).reduce((pre,cur) => pre+cur) : null;
    },
    filters: {
        formatPrice(price) {
            return price
        }
    },
    methods: {
        async getFullAddress() {
            if(!this.cep) return;
            if(this.cep.length == 8) {
				axios.get(`https://viacep.com.br/ws/${ this.cep }/json/`)
				.then( response => {
                    this.data = response.data
                    this.village = response.data.bairro
                    this.city = response.data.localidade
                    this.uf = response.data.uf
                    this.street = response.data.logradouro
                })
				.catch( error => console.log(error) )
			}
        },
        getCustomerInfo() {
            this.lastStepActive = true
        },
        async generatePaymentCode() {
            this.msgError = ''
            let allItems = Object.values( this.cartItems );
            let items = allItems.map(i => `${i.quantity} - ${i.name}`);
            let txid = uniqid.time();
            
            if(!this.customerEmail || !this.customerName) {
                this.msgError = `<div class="alert alert-danger"> Você precisa informar seu e-mail e seu nome! </div>`
                return;
            }

            const data = {
                items, txid, total: this.totalPrice, name: this.customerName, email: this.customerEmail,
                street: this.street,
                village: this.village,
                city: this.city,
                uf: this.uf,
            }

            const qrCodePix = QrCodePix({
                version: '01',
                key: 'ee3261e1-b836-430d-89ac-8a9521ae1d8b',
                name: this.customerName,
                transactionId: txid, 
                message: "Pagamento CLNG store",
                value: this.totalPrice,
            });

            this.pixCopyPaste = qrCodePix.payload(); 
            const imageQrCode = await qrCodePix.base64(); 
            this.qrCode = imageQrCode
            this.sendChargeToCLNG(data)
        },
        async sendChargeToCLNG(data) {
            // enviar para os emails do comprador e do vendedor!
            console.log(data)
        }
    }
}
</script>