<template>
    <div class="base-layout">
        <!-- Navigation-->
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container px-4 px-lg-5">
                <a class="navbar-brand" href="/"><strong>CLNG</strong> store</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
                        
                    </ul>
                    <form class="d-flex" v-if="cartButtonVisible">
                        <button class="btn btn-outline-dark" type="button" @click="toToCheckout()">
                            <i class="bi-cart-fill me-1"></i>
                            Carrinho
                            <span class="badge bg-dark text-white ms-1 rounded-pill"> {{itemsQuantityInCart}} </span>
                        </button>
                    </form>
                </div>
            </div>
        </nav>
        
        <!-- Section CONTENT-->
        <slot />

        <!-- Footer-->
        <footer class="py-5 bg-dark">
            <div class="container"><p class="m-0 text-center text-white">Todos os direitos reservados &copy; CLNG 2023</p></div>
        </footer>
    </div>
</template>

<script>
export default {
    props: ['itemsQuantityInCart', 'cart'],
    methods: {
        toToCheckout() {
            let data = this.cart
            this.$router.push({
                name: 'Checkout',
                params: data
            })
        }
    },
    data() {
        return { cartButtonVisible: true }
    },
    mounted() {
        if(this.$route.name == 'Checkout') { this.cartButtonVisible = false }
    }
}
</script>