<template>
  <div class="home">
    <Base :itemsQuantityInCart="itemsQuantityInCart" :cart="cart">
      <!-- Header-->
      <Header />
      
      <section class="py-5">
            <div class="container px-4 px-lg-5 mt-5">
                <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">

                    <div class="col mb-5" v-for="(item, index) in items" :key="index">
                        <div class="card h-100">
                            <!-- Product image-->
                            <img class="card-img-top" :src="item.image" alt="..." />
                            <!-- Product details-->
                            <div class="card-body p-4">
                                <div class="text-center">
                                    <!-- Product name-->
                                    <h5 class="fw-bolder">  <span v-html="item.name"></span> </h5>
                                    <!-- Product price-->
                                    <span class="text-muted text-decoration-line-through" v-if="item.price.from">R${{item.price.from}}</span> R${{item.price.to}}
                                </div>
                            </div>
                            <!-- Product actions-->
                            <div class="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                <div class="text-center"><a class="btn btn-outline-dark mt-auto" href="javascript:void(0)" @click="addToCart(item)">Adicionar no Carrinho</a></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </Base>
  </div>
</template>

<script>
import Base from '@/layouts/Base.vue'
import Header from '@/layouts/Header.vue';

export default {
  components: {
    Base,
    Header,
  },
  data() {
    return {
      items: [
        { id: 1, name: `Camiseta CLNG &bullet; Edição limitada`, price: {from: '169,90', to: '139,90'}, stocked: true, image: '/images/camiseta-branca-clng.jpg' },
        { id: 2, name: `Camiseta CLNG Ltda + Caneca Calango`, price: {from: '219,90', to: '179,00'}, stocked: false, image: '/images/camiseta-branca-clng-e-caneca.jpg' },
        { id: 3, name: `Camiseta CLNG Passeio Black`, price: {from: '', to: '119,90'}, stocked: true, image: '/images/camiseta-preta-clng.jpeg' },
      ],
      cart: {},
      itemsQuantityInCart: 0
    }
  },
  methods: {
    addToCart(item) {
      let newQuantity = this.cart[item.id] ? this.cart[item.id].quantity += 1 : 1
      this.cart[item.id] = {name: item.name, price: item.price.to, quantity: newQuantity, image: item.image}
      this.itemsQuantityInCart = Object.keys(this.cart).length;
    }
  }
}
</script>
